<template>
  <loader v-if="loading" />

  <event-details v-else class="route-content" :event="event" />
</template>

<script>
import { getEventById } from "@/models/events";
import EventDetails from "./EventDetails.vue";
import Loader from "./Loader.vue";

export default {
  components: { Loader, EventDetails },
  name: "ViewEvent",
  data: () => ({ event: null, loading: true }),
  mounted() {
    const { eventId } = this.$route.params;
    if (eventId) this.fetchEvent(eventId);
  },
  methods: {
    async fetchEvent(eventId) {
      const event = await getEventById(eventId);
      this.event = event;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
#view-event {
  padding-top: 0;
}
</style>
